import React, { useState, useEffect } from "react";
import Timer from "./Timer";
import AudioPlayer from "./AudioPlayer";
import TypeWriter from "./TypeWriter";
import Version from "./Version";

const App = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [duration, setDuration] = useState(300);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const setAppHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setAppHeight();
    window.addEventListener("resize", setAppHeight);

    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const handleStart = () => {
    document.getElementById("keepAwakeVideo").play();
    setIsRunning(true);
  };
  const handleStop = () => {
    document.getElementById("keepAwakeVideo").pause();
    setIsRunning(false);
  };
  const handleReset = () => {
    setIsRunning(false);
    setDuration(300);
    setKey((prevKey) => prevKey + 1);
  };
  const handleDurationChange = (newDuration) => setDuration(newDuration);

  return (
    <div
      className="bg-sigin-bg flex flex-col items-center justify-between p-4 font-sans overflow-hidden"
      style={{ height: "calc(var(--vh, 1vh) * 100)" }}
    >
      <div className="w-full max-w-md text-center space-y-6">
        <h1 className="text-4xl font-bold text-sigin-text font-caveat">
          Sigin
        </h1>
        <div>
          <TypeWriter
            text="Hi... I'm Sigin, and I'm here to help..."
            speed={70}
          />
        </div>
        <Timer
          key={key}
          duration={duration}
          isRunning={isRunning}
          onDurationChange={handleDurationChange}
        />
        <div className="flex justify-center space-x-4">
          <button
            onClick={isRunning ? handleStop : handleStart}
            className={`px-6 py-2 rounded-full text-white font-medium ${
              isRunning
                ? "bg-sigin-purple hover:bg-opacity-90"
                : "bg-sigin-green hover:bg-opacity-90"
            }`}
          >
            {isRunning ? "Stop" : "Start"}
          </button>
          <button
            onClick={handleReset}
            className="px-6 py-2 rounded-full text-white font-medium bg-sigin-purple hover:bg-opacity-90"
          >
            Reset
          </button>
        </div>
        <AudioPlayer isPlaying={isRunning} />
      </div>
      <div className="w-full text-center mt-4">
        <Version version={process.env.REACT_APP_VERSION} />
      </div>
      <video
        id="keepAwakeVideo"
        autoplay
        loop
        muted
        playsinline
        width="320"
        height="240"
        controls
      >
        <source src="data:video/mp4;base64,AAAAIGZ0eXBtcDQyAAAAAG1wNDFtcDQyAAAAAm1vb3YAAABsbW92aWUAABNtb3ZpAAAAIG1kYXQAAACBZGxyaXN0AAAAbGlzdAAAADGNvcmdpAAAAAG1pZGkAAAACAAAAFgAAAAWtbW9vdgAAAGxtdmhkAAAAANrVFwAAAAG0ABQOAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADVFwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIJpb2RzAAAAAAAAAAACAAAAAgAAAAWc3RibAAAAGdzdHNkAAAADAAAAAoAAAACAAAACQAAAAAAAAABAAAACQAAAAAAAAABAAAAbHN0c2QAAAAkc3R0cwAAAAAAAAABAAAABQAAAAEAAAABAAAAAQAAAAAAAAEAAAABAAAAAQAAAAEAAAAAAAgAZnJlZQAAAFxtZGF0AAAAIgB54AAAAAMAAf/Z" />
      </video>
    </div>
  );
};

export default App;
